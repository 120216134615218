import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ABOUT_ID } from 'constants/system';

import { Wrapper as AboutUsWrapper } from 'containers/Wrapper';
import { Container as AboutUsContainer } from 'containers/Container';

import {
  SECTION_TITLE,
  getProjectsDetails,
  getYearsDetails,
  getPeopleDetails,
  getCustomersDetails,
} from './constants';
import { Item } from './Item';

const AboutUsContent = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;

  @media screen and ${({ theme }) => theme.devices.sm} {
    grid-gap: 130px 40px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const AboutUs = () => {
  const [softtecoNumbers, setSofttecoNumbers] = useState();

  const projectsDetails = getProjectsDetails(softtecoNumbers?.projects);
  const yearsDetails = getYearsDetails();
  const peopleDetails = getPeopleDetails(softtecoNumbers?.people);
  const customersDetails = getCustomersDetails(softtecoNumbers?.customers);

  useEffect(() => {
    fetch('./softteco-numbers-data.json', {
      cache: 'no-cache',
      headers: {
        Pragma: 'no-cache',
      },
    })
      .then((response) => response.json())
      .then((json) => setSofttecoNumbers(json));
  }, []);

  return (
    <AboutUsWrapper id={ABOUT_ID}>
      <AboutUsContainer title={SECTION_TITLE}>
        <AboutUsContent>
          <Item {...projectsDetails} />
          <Item {...yearsDetails} />
          <Item {...peopleDetails} />
          <Item {...customersDetails} />
        </AboutUsContent>
      </AboutUsContainer>
    </AboutUsWrapper>
  );
};

export default AboutUs;
