import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SIZE } from 'constants/system';
import { EMAIL } from 'constants/contacts';

import Modal from 'components/Modal';

const SubTitle = styled.p`
  margin-top: 25px;
`;

export const PrivacyPolicy = ({ isVisible, onClose }) => (
  <Modal
    isVisible={isVisible}
    onClose={onClose}
    title="Privacy Policy"
    styles={{
      width: '90%',
      maxHeight: '90%',
      maxWidth: SIZE.xl,
    }}
  >
    <p>Effective date: October 1, 2019</p>
    <p>
      Privacy Policy describes the manner in which SoftTeco collects, uses,
      maintains and discloses information collected from users (each, a
      &quot;User&ldquo;) of the{' '}
      <a href={location.origin} target="_blank" rel="noreferrer">
        {location.origin}
      </a>{' '}
      website (&quot;Site&ldquo;).
    </p>

    <SubTitle>
      <b>Personal identification information</b>
    </SubTitle>
    <p>
      We may collect personal identification information from Users in a variety
      of ways, including, but not limited to, when Users visit our site or fill
      out a Contact form and in connection with other activities, services,
      features or resources we make available on our Site. Users may be asked
      for, as appropriate, name, email address, phone number, etc. We will
      collect personal identification information from Users only if they
      voluntarily submit such information to us.
    </p>
    <p>
      Users can always refuse to supply personally identification information,
      except that it may prevent them from engaging in certain Site related
      activities.
    </p>

    <SubTitle>
      <b>Non-personal identification information</b>
    </SubTitle>
    <p>
      We may collect non-personal identification information about Users
      whenever they interact with our Site. Non-personal identification
      information may include the browser name, the type of device and technical
      information about Users means of connection to our Site, such as the
      operating system and the Internet service providers utilized and other
      similar information.
    </p>

    <SubTitle>
      <b>Web browser cookies</b>
    </SubTitle>
    <p>
      We collect browser cookies - small piece of data that is sent to your
      browser from a Web server and placed on the web browser hard drive for
      record-keeping purposes and sometimes to track analytics information. User
      may choose to set their web browser to refuse cookies, or to alert you
      when cookies are being sent. If they do so, note that some parts of the
      Site may not function properly and some features may not work as intended.
    </p>

    <SubTitle>
      <b>How we use collected information</b>
    </SubTitle>
    <p>
      SoftTeco may collect and use Users personal information for the following
      purposes:
    </p>
    <p>
      <b>
        <i>To provide services</i>
      </b>
    </p>
    <p>
      Any personal identification information Users submit will be be used to
      respond to their inquiries, and/or other requests or questions.
    </p>
    <p>
      <b>
        <i>To improve user experience</i>
      </b>
    </p>
    <p>
      We may use information in the aggregate to understand how our Users as a
      group use the services and resources provided on our Site.
    </p>
    <p>
      <b>
        <i>To improve our Site</i>
      </b>
    </p>
    <p>
      We continually strive to improve our website offerings based on the
      information we receive from you.
    </p>
    <p>
      <b>
        <i>To improve customer service</i>
      </b>
    </p>
    <p>
      Your information helps us to more effectively respond to your customer
      service requests and support needs.
    </p>
    <p>
      <b>
        <i>To send periodic emails (newsletter)</i>
      </b>
    </p>
    <p>
      In some cases, you can receive marketing emails that includes company
      news, updates, related product or service information, etc. If at any time
      the User would like to unsubscribe from receiving future emails, we
      include an unsubscribe option at the bottom of each email.
    </p>

    <SubTitle>
      <b>Your rights</b>
    </SubTitle>
    <p>
      If you wish to access, amend, delete, migrate your personal information we
      store, or restrict processing of your personal information, please send
      your request to <a href={`mailto:${EMAIL}`}>{EMAIL}</a>.
    </p>

    <SubTitle>
      <b>Sharing your personal information</b>
    </SubTitle>
    <p>
      We do not sell, trade, or rent Users personal identification information
      to others.
    </p>
    <p>
      In connection with our Services and additional assignments, we may share
      your personal information with our affiliated companies located in Belarus
      or jointly owned companies and other affiliated entities we operate.
    </p>
    <p>
      Your personal information may be accessible to third-party service
      providers (such as hosting providers, marketing and analytical software
      providers, customer relationship management and accounting software
      providers and others), which are necessary to run company operations and
      provide Services.
    </p>

    <SubTitle>
      <b>How we protect your information</b>
    </SubTitle>
    <p>
      We adopt appropriate data collection, storage and processing practices and
      security measures to protect against unauthorized access, alteration,
      disclosure or destruction of your personal information and data stored on
      our Site.
    </p>

    <SubTitle>
      <b>For how long we store your information</b>
    </SubTitle>
    <p>
      Info is retained for as long as is needed for the purposes provided above.
    </p>

    <SubTitle>
      <b>
        What can you do if you no longer want to receive periodic emails
        (newsletter) from us?
      </b>
    </SubTitle>
    <p>You can opt out of any automated notifications or communications by:</p>
    <ul>
      <li>following the instructions included with each email;</li>
      <li>
        contacting us with an unsubscribe request to our email address -{' '}
        <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
      </li>
    </ul>

    <SubTitle>
      <b>Your consent</b>
    </SubTitle>
    <p>
      By using our site, you accept our privacy policy. If you do not consent to
      this policy, please do not use our Site. Your continued use of the Site
      following the posting of changes to this policy will be deemed your
      acceptance of those changes.
    </p>

    <SubTitle>
      <b>Changes to this Privacy Policy</b>
    </SubTitle>
    <p>
      SoftTeco has the discretion to update this privacy policy at any time. We
      strongly recommend you to check this page for any changes in order to stay
      informed about how we are helping to protect the personal information we
      collect. You acknowledge and agree that it is your responsibility to
      review this privacy policy periodically and become aware of modifications.
    </p>

    <SubTitle>
      <b>Contacting us</b>
    </SubTitle>
    <p>
      If you have any questions regarding this Privacy Policy, please contact us
      at: <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
    </p>
  </Modal>
);

PrivacyPolicy.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(PrivacyPolicy);
