import { widthLessThan } from 'utils';

// breakpoints

export const SIZE = {
  xs: '320px',
  sm: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

// devices

export const IS_MOBILE = widthLessThan(SIZE.sm);
export const IS_TABLET = widthLessThan(SIZE.lg);
export const IS_TOUCH_DEVICE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent,
);

// slide class

export const SLIDEUP_CLASS = 'slideup';
export const SLIDEDOWN_CLASS = 'slidedown';

// ids

export const HOME_ID = 'home';
export const SERVICE_ID = 'service';
export const ABOUT_ID = 'about';
export const PORTFOLIO_ID = 'portfolio';
export const CONTACT_US_ID = 'contact-us';

// load statuses

export const PENDING = 'PENDING';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

//

export const CORPORATE_SITE = 'https://softteco.com/';
