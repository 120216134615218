import React from 'react';

import { YEAR_OF_LAUNCH } from 'constants/info';

export const SECTION_TITLE = 'About us';

export const getProjectsDetails = (number = '') => ({
  number: `${number}+`,
  title: 'Successful projects',
  description: (
    <>
      SoftTeco has successfully completed over {number} projects across various
      domains, including{' '}
      <b>healthcare, education, e-commerce, and business intelligence.</b>
    </>
  ),
});

export const getYearsDetails = () => ({
  number: `${new Date().getFullYear() - YEAR_OF_LAUNCH}+`,
  title: 'Years in operation',
  description: (
    <>
      Since {YEAR_OF_LAUNCH}, our company has been delivering robust and{' '}
      <b>high-performing software solutions</b> and remains among the leading
      software providers according to trusted sources.
    </>
  ),
});

export const getPeopleDetails = (number = '') => ({
  number: `${number}+`,
  title: 'Full-time it experts',
  description: (
    <>
      SoftTeco unites over {number} talented IT professionals, including{' '}
      <b>
        software engineers, QA specialists, business analysts, and designers.
      </b>
    </>
  ),
});

export const getCustomersDetails = (number = '') => ({
  number: `${number}+`,
  title: 'Clients worldwide',
  description: (
    <>
      We partner with clients from{' '}
      <b>North America, Canada, the UK, Europe, and other countries</b> and
      deliver software solutions for businesses of any size and complexity.
    </>
  ),
});
